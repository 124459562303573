import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { isWindows, isMacOs, isIOS } from "react-device-detect";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import "./NavDrawer.css";
import {
  SwipeableDrawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
  IconButton,
  useTheme,
  Box,
  Typography,
} from "@mui/material";

import {
  AccountCircle,
  ExitToApp,
  VpnKey,
  PersonAdd,
  Store,
  MenuOutlined,
  Language,
  Security,
  Assignment,
  Favorite,
  LocalMall,
  LocalMallSharp,
  Brightness7,
  Brightness4,
  Info,
} from "@mui/icons-material";

import { logoutUser, profileToggle, userDialogToggle } from "../../../ToolKit/Slices/UserSlice";
import { viewCategory, viewFavCategory } from "../../../ToolKit/Slices/MenuSlice";
import { resetCustomize } from "../../../ToolKit/Slices/ItemSlice";
import { ordersContainerToggle } from "../../../ToolKit/Slices/OrderSlice";
import {
  policyDialogToggle,
  setInfoClicked,
  shopDataToggle,
  termsDialogToggle,
} from "../../../ToolKit/Slices/ShopSlice";
import { ColorModeContext } from "../../../main";
import { BsInfo, BsInfoCircle } from "react-icons/bs";
import PaymentMethods from "../../Shop/PaymentMethods/PaymentMethods";
import SocialIcons from "../../SocialIcons/SocialIcons";
import QRCode from "qrcode.react";

export default function SwipeableTemporaryDrawer() {
  const { t, i18n } = useTranslation();
  console.log("i18n.language", i18n.language);
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  const [state, setState] = React.useState({ right: false });

  const currUser = useSelector((state) => state.user.currUser);
  const version = useSelector((state) => state.shop.shopData?.settings?.version);
  const dispatch = useDispatch();

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
    handleClose();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const androidURL = useSelector((state) =>
    state.shop.homepage.androidURL ? state.shop.homepage.androidURL : ""
  );
  const iosURL = useSelector((state) =>
    state.shop.homepage.iosURL ? state.shop.homepage.iosURL : ""
  );
  const appAdActive = useSelector((state) =>
    state.shop.homepage.appAdActive ? state.shop.homepage.appAdActive : false
  );

  const appInfo = {
    imageUrl: "./logos/logo192.png",
    badge: isIOS ? "ios.png" : "play.png",
    linkUrl: isIOS ? iosURL : androidURL,
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const list = (anchor) => (
    <div
      className={clsx("list", {
        fullList: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={open ? toggleDrawer(anchor, false) : null}
      onKeyDown={open ? toggleDrawer(anchor, false) : null}
    >
      <div className="relative">
        <List dense>
          {currUser ? (
            <React.Fragment>
              <ListItem onClick={() => dispatch(profileToggle(true))}>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary={currUser.name} />
              </ListItem>
              <ListItem
                onClick={() => {
                  dispatch(viewFavCategory());
                  dispatch(resetCustomize());
                  setState({ right: false });
                }}
              >
                <ListItemIcon>
                  <Favorite />
                </ListItemIcon>
                <ListItemText primary={t("categoryNav.favorites")} />
              </ListItem>
              <ListItem
                onClick={() => {
                  // dispatch(actions.viewOrdersCategory());
                  dispatch(ordersContainerToggle(true));
                  dispatch(resetCustomize());
                  setState({ right: false });
                }}
              >
                <ListItemIcon>
                  <LocalMall />
                </ListItemIcon>
                <ListItemText primary={t("categoryNav.myOrders")} />
              </ListItem>
              <ListItem
                onClick={() => {
                  dispatch(logoutUser());
                  dispatch(viewCategory(1));
                }}
              >
                <ListItemIcon>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText primary={t("topBar.logout")} />
              </ListItem>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ListItem
                onClick={() => dispatch(userDialogToggle({ toggle: true, userAction: "login" }))}
              >
                <ListItemIcon>
                  <VpnKey />
                </ListItemIcon>
                <ListItemText primary={t("topBar.login")} />
              </ListItem>
              <ListItem
                onClick={() => dispatch(userDialogToggle({ toggle: true, userAction: "register" }))}
              >
                <ListItemIcon>
                  <PersonAdd />
                </ListItemIcon>
                <ListItemText primary={t("topBar.register")} />
              </ListItem>
            </React.Fragment>
          )}
          <ListItem onClick={() => dispatch(ordersContainerToggle(true))}>
            <ListItemIcon>
              <LocalMallSharp />
            </ListItemIcon>
            <ListItemText primary={t("userProfile.orders")} />
          </ListItem>
          <Divider />
          <ListItem onClick={() => dispatch(shopDataToggle(true))}>
            <ListItemIcon>
              <Store />
            </ListItemIcon>
            <ListItemText primary={t("topBar.contact")} />
          </ListItem>

          <ListItem onClick={handleMenu}>
            <ListItemIcon>
              <Language />
            </ListItemIcon>
            <ListItemText primary={t("topBar.language")} />
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              edge="end"
              onClick={handleMenu}
              color="inherit"
              size="small"
            ></IconButton>
            <Menu
              sx={(theme) => ({
                "& .Mui-selected": {
                  background: theme.palette.primary.main,
                  color:"white"
                },
              })}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              {/* <MenuItem
                onClick={() => {
                  handleClose();
                  // i18n.changeLanguage("en");
                  window.location.reload();
                }}
              >
                Auto
              </MenuItem> */}
              <MenuItem
                onClick={() => {
                  handleClose();
                  i18n.changeLanguage("de");
                  window.location.reload();
                }}
                selected={i18n.language === "de"}
              >
                Deutsch
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleClose();
                  i18n.changeLanguage("en");
                  window.location.reload();
                }}
                selected={i18n.language === "en"}
              >
                English
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  i18n.changeLanguage("tr");
                  window.location.reload();
                }}
                selected={i18n.language === "tr"}
              >
                Turkish
              </MenuItem>
            </Menu>

            <span style={{ fontSize: "13px" }}>
              {i18n.language === "en"
                ? "(EN) "
                : i18n.language === "de"
                ? "(DE) "
                : i18n.language === "tr"
                ? "(TR) "
                : "(AUTO)"}{" "}
            </span>
          </ListItem>
          <ListItem
            onClick={() => {
              colorMode.toggleColorMode();
            }}
          >
            <ListItemIcon>
              {theme.palette.mode === "dark" ? <Brightness7 /> : <Brightness4 />}
            </ListItemIcon>
            <ListItemText primary={theme.palette.mode === "dark" ? "Light" : "Dark"} />
          </ListItem>
          <Divider />
          <ListItem onClick={() => dispatch(termsDialogToggle(true))}>
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText primary={t("topBar.termsOfUsage")} />
          </ListItem>
          <ListItem onClick={() => dispatch(policyDialogToggle(true))}>
            <ListItemIcon>
              <Security />
            </ListItemIcon>
            <ListItemText primary={t("topBar.dataPolicy")} />
          </ListItem>
          <ListItem onClick={() => dispatch(setInfoClicked(true))}>
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText primary={"Report"} />
          </ListItem>

          {appAdActive ? (
            <React.Fragment>
              <Divider style={{ marginBottom: "10px" }} />
              <Box className="flex items-center justify-center gap-0  w-full">
                <Box className="flex flex-col gap-2 items-center">
                  <QRCode
                    id="shop-qr"
                    value={appInfo.linkUrl}
                    // size={200}
                    width={100}
                    height={100}
                    level={"H"}
                    includeMargin={true}
                  />
                  <img
                    src={`https://orionid-images.s3.eu-central-1.amazonaws.com/common/app/${appInfo.badge}`}
                    width="140px"
                    alt="app-download"
                    onClick={() => window.open(appInfo.linkUrl)}
                  />
                </Box>

                {isWindows || isMacOs ? (
                  <Box className="flex flex-col gap-2 items-center">
                    <QRCode
                      id="shop-qr"
                      value={iosURL}
                      // size={200}
                      width={100}
                      height={100}
                      level={"H"}
                      includeMargin={true}
                    />

                    <img
                      src={`https://orionid-images.s3.eu-central-1.amazonaws.com/common/app/ios.png`}
                      width="140px"
                      alt="app-download"
                      onClick={() => window.open(iosURL)}
                    />
                  </Box>
                ) : null}
              </Box>
            </React.Fragment>
          ) : null}
          <Divider sx={{ my: 1 }} />
          <Box className="flex  flex-col justify-center mt-2">
            <Typography className="text-center mb-0 pb-0" variant="caption">
              Available Payment Methods
            </Typography>
            <PaymentMethods />
          </Box>

          <SocialIcons />
        </List>
        <div className="h-[130px]"></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            fontSize: "12px",
            color: theme.palette.mode === "dark" ? "white" : "grey",
            background: theme.palette.mode === "dark" ? theme.palette.background.paper : "#eeeeee",
            width: "100%",
            bottom: 0,
            right: 0,
            padding: 10,
            textAlign: "right",
            height: 130,
            marginTop: 130,
          }}
        >
          <span>
            Version <b>{version}</b>
            {/* Version <b>{import.meta.env.VITE_VERSION}</b> */}
          </span>
          <span>
            Unterstützt von <b>Orionid Software Solutions</b>
          </span>
          <span>
            Alle Preise verstehen sich in Euro und sind inklusive Mehrwertsteuer. Abbildung kann vom
            original abweichen.
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Button color="inherit" onClick={toggleDrawer("right", true)}>
        <MenuOutlined />
      </Button>
      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        {list("right")}
      </SwipeableDrawer>
    </div>
  );
}
